import './DocumentArea.css';

import { ESignContext } from '../ESignProvider';
import React from 'react';
import { useContext } from 'react';

export const DocumentArea = () => {
  const [state] = useContext(ESignContext);

  return (
    <iframe
      src={
        'https://docs.google.com/viewer?pid=explorer&efh=false&a=v&chrome=false&embedded=true&url=' +
        encodeURIComponent(state.selectedDocument?.url || '')
      }
      frameBorder={0}
      title="Document Requires Signing"
      className="document-well"
    >
      {state.selectedDocument?.title}
    </iframe>
  );
};
