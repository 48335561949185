import { Context, useEffect } from 'react';
import { ESignState, initialEsignState } from './EsignState';
import React, { useState } from 'react';

const ESignContext: Context<[ESignState, any]> = React.createContext([
  {},
  () => {},
]);

const ESignProvider = (props: {
  caseId: string;
  password: string;
  children: any;
}) => {
  const [state, setState] = useState(initialEsignState);

  const loadDocuments = (caseId: string, password: string) => () => {
    fetch(
      initialEsignState.apiUrl +
        'api/clients/' +
        caseId +
        '/' +
        password +
        '/documents'
    )
      .then((r) => r.json())
      .then(
        (r) =>
          setState({
            ...state,
            documentsLoaded: true,
            company: {
              name: r.data.domain.name,
              url: r.data.domain.avatar,
            },
            documents: r.data.documents.map((i: any) => {
              return {
                id: i.id,
                url: i.url,
                title: i.document.name,
                completed: i.esignComplete,
              };
            }),
          }),
        (e) => {
          setState({
            ...state,
            documentsLoaded: false,
            documents: [],
          });
        }
      );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadDocuments(props.caseId, props.password), []);

  return (
    <ESignContext.Provider value={[state, setState]}>
      {props.children}
    </ESignContext.Provider>
  );
};

export { ESignContext, ESignProvider };
