import './DocumentList.css';

import { Document, ESignState } from '../EsignState';

import { ESignContext } from '../ESignProvider';
import { ListGroup } from 'react-bootstrap';
import React from 'react';
import { useContext } from 'react';

export const DocumentList = () => {
  const [state, setState] = useContext(ESignContext);

  const viewDocument = (document: Document) => () => {
    setState((state: ESignState) => ({
      ...state,
      selectedDocument: document,
    }));
  };

  return (
    <ListGroup className="list-footer">
      {state.documents?.map((item, key) => (
        <ListGroup.Item
          key={item.id}
          action
          variant={item.completed ? 'success' : 'warning'}
          onClick={viewDocument(item)}
          active={state.selectedDocument?.id === item.id}
        >
          {item.title}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};
