import './CompanyLogo.css';

import { ESignContext } from '../ESignProvider';
import React from 'react';
import { useContext } from 'react';

export const CompanyLogo = () => {
  const [state] = useContext(ESignContext);

  return (
    <img
      src={state.company?.url}
      className="company-logo"
      alt={state.company?.name}
    />
  );
};
