export interface Document {
  id: string;
  title: string;
  url: string;
  completed: boolean;
}

export interface ESignState {
  apiUrl?: string;
  documentsLoading?: boolean;
  caseId?: string;
  password?: string;
  ipAddress?: string;
  documentsLoaded?: boolean;
  signingDocument?: boolean;
  documentSigned?: boolean;
  selectedDocument?: Document | null;
  company?: {
    name: string;
    url: string;
  };
  documents?: Document[];
}

export const initialEsignState: ESignState = {
  apiUrl:
    process.env.REACT_APP_ENV === 'staging'
      ? 'https://chief-backend-develop.herokuapp.com/v1/'
      : process.env.REACT_APP_ENV === 'local'
      ? 'http://localhost:3000/v1/'
      : 'https://chief-backend-master.herokuapp.com/v1/',
  documentsLoading: true,
  documentsLoaded: false,
  documentSigned: false,
  signingDocument: false,
  selectedDocument: null,
  company: {
    name: '',
    url: '',
  },
  documents: [],
};
