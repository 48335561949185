import { Alert, Col, Row } from 'react-bootstrap';
import React, { useContext } from 'react';

import { DocumentArea } from './DocumentArea/DocumentArea';
import { DocumentList } from './DocumentList/DocumentList';
import { ESignContext } from './ESignProvider';
import { SignatureArea } from './SignatureArea/SignatureArea';

export const DocumentViewer = (props: { caseId: string; password: string }) => {
  const [state] = useContext(ESignContext);

  return (
    <Row>
      <Col>
        <DocumentList></DocumentList>
      </Col>
      <Col sm={9}>
        {state.selectedDocument !== null ? <DocumentArea></DocumentArea> : null}
        {state.selectedDocument !== null ? (
          state.selectedDocument?.completed === false ? (
            <SignatureArea
              caseId={props.caseId}
              password={props.password}
            ></SignatureArea>
          ) : (
            <Alert variant="success">
              <strong>Thank you</strong> - This document has been signed.
            </Alert>
          )
        ) : null}
      </Col>
    </Row>
  );
};
