import './App.css';

import { Col, Container, Row } from 'react-bootstrap';

import { CompanyLogo } from './ESignProvider/CompanyLogo/CompanyLogo';
import { DocumentViewer } from './ESignProvider/DocumentViewer';
import { ESignProvider } from './ESignProvider/ESignProvider';
import { Introduction } from './ESignProvider/Introduction/Introduction';
import React from 'react';

export const App = (pass: any) => {
  return (
    <ESignProvider
      caseId={pass.match.params.caseId}
      password={pass.match.params.password}
    >
      <Container>
        <Row className="row-spacing">
          <Col className="text-center">
            <CompanyLogo></CompanyLogo>
          </Col>
        </Row>
        <Row className="row-spacing">
          <Col className="text-center">
            <Introduction></Introduction>
          </Col>
        </Row>
        <Row className="row-spacing">
          <Col>
            <DocumentViewer
              caseId={pass.match.params.caseId}
              password={pass.match.params.password}
            ></DocumentViewer>
          </Col>
        </Row>
      </Container>
    </ESignProvider>
  );
};
