import './SignatureArea.css';

import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useContext} from 'react';
import React, {useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';

import {ESignContext} from '../ESignProvider';
import {signDocumentCall} from './SignatureAreaService';

export const SignatureArea = (props: { caseId: string; password: string }) => {
  const [state, setState] = useContext(ESignContext);
  const [signing, setSigningState] = useState(false);

  let sigPad: any = null;

  const clear = () => {
    sigPad.clear();
  };

  const signDocument = signDocumentCall(
    props.caseId,
    props.password,
    state.selectedDocument?.id || '',
    state,
    setState,
    setSigningState
  );

  return (
    <div>
      <div className="signature-well">
        <Form>
          {signing ? (
            <div className="overlay">
              <div className="loader">
                <FontAwesomeIcon icon={faSpinner} spin />
                <p>Signing your document</p>
                <p>Please Wait</p>
              </div>
            </div>
          ) : (
            ''
          )}

          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Signature
            </Form.Label>
            <Col sm="10" className="signature-holder">
              <SignatureCanvas
                ref={(ref) => {
                  sigPad = ref;
                }}
                penColor="black"
                canvasProps={{
                  className: 'sig-canvas',
                }}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Date
            </Form.Label>
            <Col sm="10">
              <Form.Control
                plaintext
                readOnly
                type="text"
                defaultValue={new Date().toLocaleDateString()}
              ></Form.Control>
            </Col>
          </Form.Group>
        </Form>
      </div>

      <Row className="signature-button-padding">
        <Col className="text-left">
          <Button variant="danger" disabled={signing} onClick={clear}>
            Clear
          </Button>
        </Col>

        <Col className="text-right">
          <Button
            variant="success"
            disabled={signing}
            onClick={() => signDocument(sigPad.toDataURL())}
          >
            Sign Document
          </Button>
        </Col>
      </Row>
    </div>
  );
};
