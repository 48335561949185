import { initialEsignState } from '../EsignState';

export const signDocumentCall = (
  caseId: string,
  password: string,
  documentId: string,
  state: any,
  setState: any,
  setSigningState: any
) => (signature: any) => {
  setSigningState(true);
  fetch(`https://geolocation-db.com/json/`)
    .then((res) => res.json())
    .then((json) => json.IPv4)
    .then((ipAddress) => {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          esignSignature: signature,
          ipAddress: ipAddress,
        }),
      };

      fetch(
        initialEsignState.apiUrl +
          'api/clients/' +
          (caseId || '') +
          '/' +
          (password || '') +
          '/documents/' +
          (documentId || ''),
        requestOptions
      )
        .then((response) => response.json())
        .then((r) => {
          setState({
            ...state,
            selectedDocument: {
              id: r.data.id,
              url: r.data.url,
              title: r.data.document.name,
              completed: r.data.esignComplete,
            },
            documents: state.documents?.map((i: any) => {
              if (i.id === state.selectedDocument?.id) {
                return {
                  id: r.data.id,
                  url: r.data.url,
                  title: r.data.document.name,
                  completed: r.data.esignComplete,
                };
              } else {
                return i;
              }
            }),
          });

          setSigningState(false);
        });
    });
};
