import './Introduction.css';

import { ESignContext } from '../ESignProvider';
import React from 'react';
import { useContext } from 'react';

export const Introduction = () => {
  const [state] = useContext(ESignContext);

  return (
    <div className="introduction-area">
      <h4>Thank you for choosing to sign your documents using E-Sign.</h4>
      This secure method of displaying and signing documents is quick and easy.
      To use this method, please choose a document that requires signing from
      the list. A preview of the document will then be shown to you. Once you
      are happy with this document simply use your finger or mouse to sign in
      the box below the document and click sign. Your document will be signed
      and a copy will be sent to you and {state.company?.name}. Once all your
      documents are signed and you can close this page.
    </div>
  );
};
